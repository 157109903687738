module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Olly G's Game Deals","short_name":"Game Deals","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase-messaging/gatsby-browser.js'),
      options: {"plugins":[],"config":{"apiKey":"AIzaSyBbVfTBkaDSsGOskoV1wHcFNHcR_kk_1K8","authDomain":"game-deals-bb2bc.firebaseapp.com","databaseURL":"https://game-deals-bb2bc.firebaseio.com","projectId":"game-deals-bb2bc","storageBucket":"game-deals-bb2bc.appspot.com","messagingSenderId":"479706586867","appId":"1:479706586867:web:a94fb444712cfbde27a186","measurementId":"G-9ZM2TP0T1D"},"disableDevelopment":false,"removeFirebaseServiceWorker":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
